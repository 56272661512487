import { h, VNode } from "preact";
import { IReviewsProps } from "./types";

export function Reviews(props: IReviewsProps): VNode {
  console.log("props", props);

  return (
    <div style={{ position: "absolute", bottom: 10, left: 10 }}>
      <button>Hello, World!</button>
    </div>
  );
}
